
import { Component, Vue } from 'vue-property-decorator'
import { dayFormat } from '@/utils/formatDate'
import { SelectInsectItem, SelectInsectDetail } from '@/types/insectMonitor'

@Component
export default class HistoryMonitor extends Vue {
  private date: string[] = []
  private loading = false
  private dialogTableVisible = false
  private page = 1
  private size = 10
  private total = 0
  private tableData: SelectInsectItem[] = []
  private childLoading = false
  private childTableData: SelectInsectDetail[] = []
  private expands: any[] = []
  private childPage = 1
  private childSize = 50
  private childTotal = 0
  private rowImgId = ''
  private getMore = false
  private rowData: SelectInsectItem | null = null
  getRowKeys (row: SelectInsectItem) {
    return row.imgId
  }

  created () {
    this.getDate()
  }

  // 销毁
  destroyed () {
    window.removeEventListener('scroll', this.tableScroll)
  }

  // 获取七天前时间
  getDate () {
    const date = new Date()
    const time = date.getTime()
    const beforeMs = time - 3600 * 1000 * 24 * 7
    const beforeDate = date.setTime(beforeMs)
    this.date = [dayFormat(beforeDate), dayFormat(date.setTime(time))]
    this.onSearch()
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 智库推荐
  goRecommend (insectName: string) {
    this.$router.push({
      name: 'insectMonitorRecommendHistory',
      params: { insectName }
    })
  }

  getData () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: SelectInsectItem[] }>(
        this.$apis.insectMonitor.selectInsectList,
        {
          page: this.page,
          size: this.size,
          deviceId: this.$route.params.deviceId,
          startTime: this.date[0],
          endTime: this.date[1]
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          if (res.list && res.list.length > 0) {
            res.list.map((item) => {
              item.childTableData = []
            })
          }
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  async expandChange (row: SelectInsectItem, expandedRows: SelectInsectItem[]) {
    this.childPage = 1

    if (expandedRows.length > 0) {
      this.expands = [row.imgId]
      this.rowImgId = row.imgId
      this.rowData = row
      this.getChildData(row)
    } else {
      this.expands = []
    }
  }

  tableScroll (e: any) {
    const scrollHeight = e.target.scrollHeight
    const scrollTop = e.target.scrollTop
    const clientHeight = e.target.clientHeight
    const distance = scrollHeight - scrollTop - clientHeight
    if (distance < 60 && this.getMore) {
      // 距离小于60
      this.childPage++
      this.getMore = false
      this.rowData && this.getChildData(this.rowData)
    }
  }

  getChildData (row: SelectInsectItem) {
    this.childLoading = true
    this.$axios
      .get<{ total: number; list: SelectInsectDetail[] }>(
        this.$apis.insectMonitor.selectInsectImgResultByImgId,
        {
          page: this.childPage,
          size: this.childSize,
          imgId: this.rowImgId
        }
      )
      .then((res) => {
        this.childTotal = res.total || 0

        this.childTableData =
          this.childPage === 1
            ? res.list || []
            : this.childTableData.concat(res.list || [])
        this.getMore = this.childTableData.length < res.total
        this.tableData.forEach((temp, index) => {
          // 点击的行，
          if (row.imgId === temp.imgId) {
            this.tableData[index].childTableData = this.childTableData
          }
        })
      })
      .finally(() => {
        this.childLoading = false
      })
    this.$nextTick(() => {
      ;(this.$refs.tableWrap as any).bodyWrapper.addEventListener(
        'scroll',
        this.tableScroll
      )
    })
  }

  indexMethod (index: number) {
    return index + 1
  }
}
